export const FOOTER_LINK_BEHAVIOR_TYPE = {
	NORMAL: 'NORMAL',
	NEW_TAB: 'NEW_TAB',
	NEW_WINDOW: 'NEW_WINDOW',
}

export const FOOTER_LINK_DOMAIN_TYPE = {
	INTERNAL: 'INTERNAL',
	CMS: 'CMS',
	HELP_PAGE: 'HELP_PAGE',
	PINNACLE_SOLUTION: 'PINNACLE_SOLUTION',
}
