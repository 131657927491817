export const FAVOURITES_TYPE = {
	GAME: 'GAME',
	GAME_LEAGUE: 'GAME_LEAGUE',
	GAME_SPECIAL_LEAGUE: 'GAME_SPECIAL_LEAGUE',
	SPORT_LEAGUE: 'SPORT_LEAGUE',
	SPORT_SPECIAL_LEAGUE: 'SPORT_SPECIAL_LEAGUE',
	PARTICIPANT: 'PARTICIPANT',
}

export const LEFT_MENU_TYPE = {
	FAVOURITES: 'FAVOURITES',
	SPORTS: 'SPORTS',
}

export const UPDATE_FAVOURITES_ACTION = {
	ADD: 'ADD',
	REMOVE: 'REMOVE',
	ORDER: 'ORDER',
}
