import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { sportApiQuery } from '../RTK/sportServicesRTK'

const initialState = {
	tab: {},
	odd: {},
	section: {},
	tabList: [],
	bannerData: {},
	version: 0,
	specialVersion: 0,
	lastOddsType: null,
	lastLocale: null,
	lastEventId: null,
	lastOddUpdatedIds: [],
	lastAction: 'INIT',
}

export const EventDetail = createSlice({
	name: 'EventDetail',
	initialState,
	reducers: {
		resetEventDetailData: (state) => {
			state.tab = {}
			state.odd = {}
			state.section = {}
			state.tabList = []
			state.bannerData = {}
			state.version = 0
			state.specialVersion = 0
			state.lastOddsType = null
			state.lastLocale = null
			state.lastEventId = null
			state.lastOddUpdatedIds = []
			state.lastAction = 'INIT'
		},
		updateDataFromCache: (state, { payload }) => {
			state.tab = payload?.tab
			state.odd = payload?.odd
			state.section = payload?.section
			state.tabList = payload?.tabList
			state.bannerData = payload?.bannerData
			state.version = 0
			state.specialVersion = 0
			state.lastOddsType = null
			state.lastLocale = null
			state.lastEventId = null
			state.lastOddUpdatedIds = []
			state.lastAction = 'UPDATE_FROM_CACHE'
		},
		matchFullfilledEventDetail: (state, { payload }) => {
			state.tab = payload?.tab
			state.odd = payload?.odd
			state.section = payload?.section
			state.tabList = payload?.tabList
			state.bannerData = payload?.bannerData
			state.version = payload?.version
			state.specialVersion = payload?.specialVersion
			state.lastOddsType = payload?.lastOddsType
			state.lastLocale = payload?.lastLocale
			state.lastEventId = payload?.lastEventId
			state.lastOddUpdatedIds = payload?.lastOddUpdatedIds
			state.lastAction = payload?.action
		},
		updateBannerData: (state, { payload }) => {
			state.bannerData = payload?.bannerData
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			isAnyOf(sportApiQuery?.endpoints?.getEventDetail?.matchFulfilled),
			(state, { payload }) => {
				state.tab = payload?.tab
				state.odd = payload?.odd
				state.section = payload?.section
				state.tabList = payload?.tabList
				state.bannerData = payload?.bannerData
				state.version = payload?.version
				state.specialVersion = payload?.specialVersion
				state.lastOddsType = payload?.lastOddsType
				state.lastLocale = payload?.lastLocale
				state.lastEventId = payload?.lastEventId
				state.lastOddUpdatedIds = payload?.lastOddUpdatedIds
				state.lastAction = payload?.action
			},
		)
	},
})

export const {
	resetEventDetailData,
	updateDataFromCache,
	matchFullfilledEventDetail,
	updateBannerData,
} = EventDetail.actions
export default EventDetail.reducer
