import {
	BET_LOCATION_TRACKING,
	BET_TYPE,
	ODDS_SELECTION_TYPE,
	SELECTION_TYPE,
} from 'constants/common'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

const EXCLUDE_TEAM_TOTAL = [BET_TYPE.HOME_TOTALS, BET_TYPE.AWAY_TOTALS]

const selectionEquals = (selection, oddsId) => selection.oddsId === oddsId

export const separateOddsId = (oddsId) => {
	const [eventId, period, betType, selectionType, alt, spread, teamType] =
		oddsId.split('|').map(Number)
	return {
		eventId,
		period,
		betType,
		selectionType,
		alt,
		spread,
		teamType,
	}
}

export const getParticipants = (participants = [], reverse = false) =>
	participants
		.concat()
		.sort((a, b) => {
			const desc = reverse ? -1 : 1
			let tempA = a.type.toLowerCase()
			let tempB = b.type.toLowerCase()
			if (tempA > tempB) {
				return -1 * desc
			}
			if (tempA < tempB) {
				return 1 * desc
			}
			return 0 * desc
		})
		.map((participant) => participant)

export const getDescription = (periodLabel, betType, leagueName) => {
	return (
		<Fragment>
			<span>
				<FormattedMessage
					id={`betslip.description.bet_type.${betType}`}
					defaultMessage=''
				/>
			</span>
			{' – '}
			<span>{periodLabel}</span>
			{' – '}
			<span>{leagueName}</span>
		</Fragment>
	)
}

export const removeFromSelections = (state, { oddsId, oddsSelectionsType }) =>
	state.filter((selection) => {
		const oddsIdSelection = separateOddsId(selection.oddsId)
		const _separation = separateOddsId(oddsId)
		if (oddsSelectionsType === ODDS_SELECTION_TYPE.OUTRIGHT) {
			return oddsIdSelection.eventId !== _separation.eventId
		} else {
			return !(
				oddsIdSelection.eventId === _separation.eventId &&
				oddsIdSelection.period === _separation.period &&
				(oddsIdSelection.betType === _separation.betType ||
					(EXCLUDE_TEAM_TOTAL.includes(Number(oddsIdSelection.betType)) &&
						EXCLUDE_TEAM_TOTAL.includes(Number(_separation.betType))))
			)
		}
	})

export const addToSelections = (
	state,
	{
		betType,
		oddsSelectionsType,
		oddsFormat,
		selectionId,
		eventName,
		contestantName,
		leagueId,
		leagueName,
		eventId,
		participants,
		seasonId,
		teamType,
		odds,
		oddsId,
		sportId,
		sportName,
		handicap,
		selectedAt,
		sport,
		homeTeamType,
		awayTeamType,
		wager,
		parlayRestriction,
		rotNum,
		gradingUnit,
		unit,
		isSpecial,
		inplay,
		specialName,
		betLocationTracking,
	},
) => [
	{
		betType,
		oddsSelectionsType,
		oddsFormat,
		selectionId,
		eventName,
		contestantName,
		leagueId,
		leagueName,
		eventId,
		participants,
		seasonId,
		teamType,
		odds,
		oddsId,
		sportId,
		sportName,
		handicap,
		selectedAt,
		sport,
		homeTeamType,
		awayTeamType,
		wager,
		parlayRestriction,
		rotNum,
		gradingUnit,
		unit,
		isSpecial,
		inplay,
		specialName,
		betLocationTracking,
	},
	...removeFromSelections(state, { oddsId, oddsSelectionsType, seasonId }),
]

export const updateSelection = (state, { oddsId, wager, winRiskStake }) =>
	state.map((selection) => {
		if (selectionEquals(selection, oddsId)) {
			return {
				...selection,
				wager: wager,
				winRiskStake: winRiskStake,
			}
		} else {
			return selection
		}
	})

export const parseHandicap = (handicap) =>
	Number.isInteger(handicap) ? handicap.toFixed(1) : handicap

export const getTeamSelected = (selection, gradingUnit, formatMessage) => {
	const { betType, selectionType, spread } = separateOddsId(selection.oddsId)
	const handicap = betType !== BET_TYPE.THE_1X2 ? spread : ''
	const participants = getParticipants(
		selection.participants,
		selection.homeTeamType,
	)

	if (selection.contestantName && selection.unit && selection.handicap) {
		return `${selection.contestantName} ${selection.handicap} ${selection.unit}`
	}

	if (selection.oddsSelectionsType === ODDS_SELECTION_TYPE.OUTRIGHT) {
		return selection.contestantName
	}

	let teamSelected =
		participants[
			parseInt(selection.teamType) === selection.homeTeamType
				? selection.homeTeamType
				: selection.awayTeamType
		]?.name
	switch (betType) {
		case BET_TYPE.OU: {
			const translatedBetType = formatMessage({
				id: `betslip.description.bet_type.OU.${selectionType}`,
				defaultMessage: ' ',
			})
			teamSelected = `${translatedBetType} ${handicap} ${gradingUnit}`
			break
		}
		case BET_TYPE.HOME_TOTALS:
		case BET_TYPE.AWAY_TOTALS: {
			const translatedBetType = formatMessage({
				id: `betslip.description.bet_type.HA_TOTALS.${selectionType}`,
				defaultMessage: ' ',
			})
			teamSelected = `${teamSelected} - ${translatedBetType} ${handicap}`
			break
		}
		case BET_TYPE.THE_1X2: {
			if (parseInt(selectionType) === SELECTION_TYPE.DRAW) {
				teamSelected = formatMessage({
					id: `betslip.description.bet_type.ML.${selectionType}`,
					defaultMessage: ' ',
				})
			}
			teamSelected = `${teamSelected}`
			break
		}
		default:
			teamSelected = `${teamSelected} ${
				handicap > 0 ? `+${parseHandicap(handicap)}` : parseHandicap(handicap)
			}`
			break
	}
	return teamSelected
}

export const addBetTracking = ({ mainPages }) => {
	return {
		mainPages,
		marketTab: BET_LOCATION_TRACKING.UNKNOWN,
		market: BET_LOCATION_TRACKING.UNKNOWN,
		view: BET_LOCATION_TRACKING.NEW_EURO_VIEW,
		navigation: BET_LOCATION_TRACKING.NAVIGATION_SPORT,
		oddsContainerCategory: BET_LOCATION_TRACKING.MAIN,
		oddsContainerTitle: BET_LOCATION_TRACKING.UNKNOWN,
		marketType: BET_LOCATION_TRACKING.UNKNOWN,
		eventSorting: BET_LOCATION_TRACKING.LEAGUE,
		pageType: BET_LOCATION_TRACKING.UNKNOWN,
		defaultPage: BET_LOCATION_TRACKING.UNKNOWN,
		reuseSelection: false,
	}
}
