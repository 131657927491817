import { isNil, isEmpty } from 'lodash'
import { languages } from '../../constants/i18n'
import { selectLangSupport } from 'stores/RTK/selector/templateSelector'
import store from 'stores/main'
import { getConfigValue } from 'services/Common'
import { B2B_CONFIG_KEY } from 'constants/common'
import localeInstance from 'apps/types/Locale'

export const getLocale = () => {
	return localeInstance.getLanguage() || 'en_US'
}

export const getLangSupport = () => {
	const locale = languages.filter((l) =>
		(
			getConfigValue(
				B2B_CONFIG_KEY.SUPPORTED_LANGUAGES,
				selectLangSupport(store.getState()),
			) || selectLangSupport(store.getState())
		)?.includes(l.culture),
	)
	/**
	 * Array of supported language codes, eg:
	 *   ['en_US', 'zh_CN', 'vi_VN', 'zh_TW']
	 */
	const supportedCultures = locale.map((lang) => lang.culture)
	/**
	 * Array of supported language codes, eg:
	 *   ['en', 'de', 'fr', 'zh-cn']
	 */
	const supportedUrlPrefix = locale.map((lang) => lang.urlPrefix.default)
	return {
		locale,
		supportedCultures,
		supportedUrlPrefix,
	}
}

export const findByCulture = (
	culture,
	fallback = getConfigValue(B2B_CONFIG_KEY.DEFAULT_LANGUAGE, 'en_US'),
) => {
	const { locale } = getLangSupport()
	const isSupportEnglish = locale.some((l) => l.culture === 'en_US')
	const match = locale.find(
		(lang) => lang.culture.toLowerCase() === culture.toLowerCase(),
	)

	// find it, otherwise use english
	return (
		match ||
		(fallback && findByCulture(isSupportEnglish ? fallback : locale[0].culture))
	)
}

/**
 *
 * @param {[string]} langSupports
 * @returns {Object}
 */
export const mappingLanguageOptions = (langSupports = []) => {
	return langSupports.reduce((result, lang) => {
		if (
			result[lang] ||
			!languages.some(
				(lng) => lng.culture.toLocaleLowerCase() === lang.toLocaleLowerCase(),
			)
		) {
			return result
		}
		const culture = findByCulture(lang)
		result[lang] = `${culture.name} (${culture.abbr})`
		return result
	}, {})
}

export const findByUrlPrefix = (uriCulture, target = 'default') => {
	const { locale } = getLangSupport()
	return locale.find((l) => l.urlPrefix[target] === uriCulture)
}

export const getLangByCulture = (locale) => {
	return findByCulture(locale)?.urlPrefix?.default
}

export const removeEmptyValues = (arr) => arr.filter((val) => val !== '')

export const getCurrentLang = () => {
	return getLangByCulture(getLocale())
}

export const getLangForBettingRules = () => {
	let locale = getLocale()
	if (locale === 'en_US' || locale === 'tr_TR') {
		return 'en'
	}
	return locale?.toLowerCase()?.replace('_', '-')
}

export const getLanguageFromUrl = (url) => {
	const { supportedUrlPrefix } = getLangSupport()
	const parts = url.split(/[/?]/)
	const match =
		parts[3] &&
		supportedUrlPrefix.find(
			(val) => val.toLowerCase() === parts[3].toLowerCase(),
		)
	return match || getCurrentLang()
}

export const parseLanguageAPGaming = (locale) => {
	try {
		const languages = JSON.parse(window.env.languagesSupportedApGaming)
		const currentLanguage = getLangByCulture(locale)
		if (isEmpty(languages) || isNil(languages[currentLanguage])) {
			return 'en'
		}
		return languages[currentLanguage]
	} catch (e) {
		return 'en'
	}
}
