/* This file define domains of service using on API or WEB SOCKET */
export const MEMBER_SERVICES = {
	BASE: 'member-service/v2',
	ACCEPT_TERM_CONDITION: 'acceptTC',
	ACCOUNT_BALANCE: 'account-balance',
	ALL_ODDS_SELECTIONS: 'all-odds-selections',
	AUTHENTICATE: 'authenticate',
	AUTH_TOKEN: 'auth-token',
	DATA_NOCACHE: 'data.nocache',
	DATA_NOCACHE_V2: 'data.nocache-v2',
	LAST_MESSAGE: 'message/lastMessage',
	LOAD_ESPORTS_HUB_TEMPLATE: 'load-esportshub-template',
	LOAD_TEMPLATE: 'load-euro-template',
	LOGIN: 'login',
	LOGOUT: 'logout',
	MAINTENANCE_BY_LANG: 'getMaintenanceByLang',
	MY_BETS: 'my-bets',
	PARLAY_ODDS_SELECTIONS: 'parlay-odds-selections',
	SEARCH_WAGER: 'unified-account/search-wager',
	UPDATE_DEFAULT_VIEW: 'updateDefaultView',
	UPDATE_LOCALE: 'locale',
	UPDATE_STANDARD_FAVOURITE: 'standard-favourite/update',
	UPDATE_STANDARD_ESPORTS_HUB_FAVOURITE:
		'standard-esports-hub-favourite/update',
	UPDATE_MODE: 'displayMode',
	UPDATE_REASON_FAIL_DP: 'dp/floaded',
}
export const SPORTS_SERVICES = {
	BASE: 'sports-service/sv',
	COMPACT_ESPORTS_OUTRIGHT: 'compact/e-sport/outright',
	COMPACT_OUTRIGHT: 'compact/outright',
	ESPORTS_GAMES: 'euro/esport-games',
	GET_FAVOURITE: 'euro/favourites',
	GET_STANDARD_FAVOURITE: 'euro/favourites',
	GET_STANDARD_ESPORTS_HUB_FAVOURITE: 'euro/esports-hub/favourites',
	LEAGUES: 'euro/leagues',
	LEFT_MENU: 'euro/left-menu',
	ODDS: 'euro/odds',
	ODDS_EVENT: 'euro/odds/event',
	ODDS_LEAGUE: 'euro/odds/league',
	ODDS_MATCHUPS: 'euro/odds/matchups',
	ODDS_PERIODS: 'odds/periods',
	SEARCH_BY_STRING: 'euro/search',
	PARTICIPANT_MATCHUP: 'euro/odds/participant',
}
export const BET_PLACEMENT_SERVICES = {
	BASE: 'bet-placement',
	BET_SINGLES: 'buyV2',
	BET_PARLAY: 'buyParlayV2',
}

export const OTHER_SERVICES = {
	GET_CAPTCHA: 'captcha-mgmt/captcha/get',
}
