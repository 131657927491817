import { ODDS_TYPE, PRECISION_MAP } from 'constants/common'
import { Decimal } from 'decimal.js'

export default class Odds {
	constructor(value, oddsFormat) {
		this.value = new Decimal(value)
		this.format = oddsFormat
	}

	round() {
		if (this.format === ODDS_TYPE.AMERICAN) {
			return this.value.round().toNumber()
		}

		const precision = PRECISION_MAP[this.format] || PRECISION_MAP.default
		return this.value.toFixed(precision)
	}

	formatInteger() {
		switch (this.format) {
			case ODDS_TYPE.AMERICAN:
				return this.value > 0 ? `+${this.value}` : `${this.value}`
			case ODDS_TYPE.DECIMAL: {
				const precision = PRECISION_MAP[ODDS_TYPE.DECIMAL]
				return this.value.toNumber().toFixed(precision)
			}
			default:
				return this.round()
		}
	}
}
