import { removeEmptyValues } from 'services/i18n'
import { matchPath, useLocation } from 'react-router-dom'
import { routesAnotherViews, routesEsportsHub } from './routes'

export const replaceLangInRoute = (pathname, newLocale) => {
	const parts = removeEmptyValues(pathname.split('/'))
	parts[0] = newLocale
	return `/${parts.join('/')}`
}

export const useReplaceLangInRoute = () => {
	const location = useLocation()
	const parseUrlWithLang = (pathname, newLocale) => {
		const parts = removeEmptyValues(pathname.split('/'))
		parts[0] = newLocale
		return `/${parts.join('/')}${location.hash}`
	}
	return {
		parseUrlWithLang,
	}
}

export function getCurrentPage(path) {
	const keys = Object.keys(routesEsportsHub)
	let currentPage = 'base'
	for (let key of keys) {
		const pathname = routesEsportsHub[key]
		const match = matchPath(pathname, path) !== null

		// found the current page
		if (match) {
			currentPage = key
			break
		}
	}

	return currentPage
}

export function checkMatchAnotherView() {
	const pathNames = Object.values(routesAnotherViews)
	return pathNames.some((pathname) =>
		matchPath({ path: pathname, end: false }, window.location.pathname),
	)
}
