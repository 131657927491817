import { WS_MESSAGE_TYPE } from 'constants'
import Futures from 'apps/types/Futures'

export const parseWsBody = (requestBody) => {
	const result = {}
	Object.entries(requestBody).forEach(([key, value]) => {
		if (value !== '') {
			result[key] = value
		}
	})
	return result
}

export const generateUnSubscribeMessage = (destination) => ({
	type: WS_MESSAGE_TYPE.UNSUBSCRIBE,
	destination: destination,
})

export const parseBodyCachedSpecial = (
	specialEvents,
	odds,
	cached,
	view = 'euro',
	leagueId,
) => {
	const { futures } = new Futures(specialEvents, odds, cached, view, leagueId)
	const events = [...futures.values()]
	return events || []
}
