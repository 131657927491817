import { createSlice } from '@reduxjs/toolkit'
import { checkViewPort } from 'services/Browser'

const initialState = {
	view: checkViewPort(),
	width: window.innerWidth,
	height: window.innerHeight,
	showSideBar: false,
	serviceOutage: false,
}

export const Layout = createSlice({
	name: 'Layout',
	initialState,
	reducers: {
		setView: (state, { payload }) => {
			state.view = payload
		},
		setViewportDimensions: (state, { payload }) => {
			state.width = payload.width
			state.height = payload.height
		},
		setServiceOutage: (state, { payload }) => {
			state.serviceOutage = payload
		},
		setShowSideBar: (state, { payload }) => {
			state.showSideBar = payload
		},
	},
})

export const {
	setViewportDimensions,
	setView,
	setServiceOutage,
	setShowSideBar,
} = Layout.actions

export default Layout.reducer
