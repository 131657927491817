import { formatWager } from '../utils'

export const ERROR_MESSAGES = {
	ATTENTION: 'ATTENTION',
	ABOVE_MAX_BET_AMOUNT: 'ABOVE_MAX_BET_AMOUNT',
	ALL_BETTING_CLOSED: 'ALL_BETTING_CLOSED',
	BELOW_MIN_BET_AMOUNT: 'BELOW_MIN_BET_AMOUNT',
	BETTING_BUDGET_EXCEEDED: 'BETTING_BUDGET_EXCEEDED',
	OK: 'OK',
	UNAVAILABLE: 'UNAVAILABLE',
	WAGER_BELOW_MIN_RISK: 'WAGER_BELOW_MIN_RISK',
	WAGER_ABOVE_MAX_RISK: 'WAGER_ABOVE_MAX_RISK',
	CORRELATED: 'CORRELATED',
	INVALID_SELECTION: 'INVALID_SELECTION',
	INVALID_BET_ACCEPTANCE_TYPE: 'INVALID_BET_ACCEPTANCE_TYPE',
	EVENT_OFFLINE: 'EVENT_OFFLINE',
	ODDS_CHANGE: 'ODDS_CHANGE',
	RESUBMIT_REQUEST: 'RESUBMIT_REQUEST',
	LINE_CHANGED: 'LINE_CHANGED',
	LINE_ODDS_CHANGED: 'LINE_ODDS_CHANGED',
	MAX_PICKS: 'MAX_PICKS',
	INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
	SYSTEM_ERROR_3: 'SYSTEM_ERROR_3',
	ERROR3105: 'ERROR3105',
	SUSPENDED_LINE: 'SUSPENDED_LINE',
	SUSPENDED_LINE_CREDIT: 'SUSPENDED_LINE_CREDIT',
	B2B_INTERNAL_ERROR: 'B2B_INTERNAL_ERROR',
	B000: 'B000',
	ERROR1004: 'ERROR1004',
	ERROR1005: 'ERROR1005',
	E999: 'E999',
	EVENT_NO_LONGER_AVAILABLE_FOR_BETTING:
		'EVENT_NO_LONGER_AVAILABLE_FOR_BETTING',
	WAGER_TYPE_NO_LONGER_AVAILABLE_FOR_BETTING:
		'WAGER_TYPE_NO_LONGER_AVAILABLE_FOR_BETTING',
}

export const MULTIPLES = {
	...ERROR_MESSAGES,
	NOT_ALL_AVAILABLE: 'NOT_ALL_AVAILABLE',
	INVALID_LEGS: 'INVALID_LEGS',
	UNAVAILABLE: 'MULTIPLES_UNAVAILABLE',
	UNSUPPORTED_MULTIPLES: 'UNSUPPORTED_MULTIPLES',
	ROUND_ROBIN_DISALLOWED: 'ROUND_ROBIN_DISALLOWED',
}

export const SINGLES = {
	...ERROR_MESSAGES,
}

export const BETSLIP_SECTION = {
	MULTIPLES: 'MULTIPLES',
	SINGLES: 'SINGLES',
	TEASERS: 'TEASERS',
}

export const MESSAGES_TYPE = {
	ERROR: 'ERROR',
	WARNING: 'WARNING',
	ODDS_CHANGE: 'ODDS_CHANGE',
}

export const validInputWager = (min, max, wager) =>
	[
		min > formatWager(wager) ? ERROR_MESSAGES.WAGER_BELOW_MIN_RISK : '',
		'',
		max < formatWager(wager) ? ERROR_MESSAGES.WAGER_ABOVE_MAX_RISK : '',
	].join('')

const messages = {
	[BETSLIP_SECTION.MULTIPLES]: {
		[MESSAGES_TYPE.WARNING]: {
			...MULTIPLES,
		},
		[MESSAGES_TYPE.ERROR]: {
			...MULTIPLES,
		},
	},
	[BETSLIP_SECTION.SINGLES]: {
		[MESSAGES_TYPE.WARNING]: {
			...SINGLES,
		},
		[MESSAGES_TYPE.ERROR]: {
			...SINGLES,
		},
	},
}

export default messages
