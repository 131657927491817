import { PIXEL_ATTRIBUTES, breakpoints } from 'styles/themes/base.styled'
import { css } from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'
import { THEME_MODE } from '../../constants'

/**
 *
 * values: {
 * 	[MEDIA.DESKTOP]: {
 * 		margin: 10px,
 * 		padding: 10px,
 * 		font-size: 10,
 * 	},
 * 	[MEDIA.TABLET]: {
 * 		margin: 8px,
 * 		padding: 8px,
 * 		font-size: 8,
 * 	}
 * }
 *
 */
export const usingMedia = (values = {}, mediaQueryStyle = 'max-width') => {
	const styles = Object.entries(values).reduce((result, [key, value]) => {
		return (result += `
			@media only screen and (${mediaQueryStyle}: ${breakpoints[key]}px) {
				${value}
			}
		`)
	}, '')
	return css([styles])
}

export const generatePropsStyle = (attributes, propsMapping = []) => {
	const styles = Object.entries(attributes).reduce((result, [key, value]) => {
		if (propsMapping.some((prop) => prop === key)) {
			const _key = key.replace('$', '')
			const attribute = kebabCase(_key)
			return (result += `
        ${attribute}: ${
				PIXEL_ATTRIBUTES.includes(attribute) ? `${value}px` : value
			};
		`)
		}
		return result
	}, '')
	return styles !== '' ? css([styles.trim()]) : null
}

export const getValidTheme = (colorsMap, componentsMap, mode) => {
	const themeName = Object.keys(componentsMap).includes(window.env.themeName)
		? window.env.themeName
		: 'default'
	const colors = get(colorsMap, [themeName, mode])
	const components = get(componentsMap, [themeName, mode])
	if (colors && components) {
		return {
			themeName: themeName,
			colors: colors,
			components: components,
		}
	}

	const modeDefault = Object.keys(componentsMap[themeName])[0]
	return {
		themeName: themeName,
		colors: get(colorsMap, [themeName, modeDefault]),
		components: get(componentsMap, [themeName, modeDefault]),
	}
}

export const getThemeFromDataNoCache = ({
	auth,
	displayMode,
	enableLightMode,
	enableDarkMode,
	enableDarkModeForGuest,
	isB2B,
}) => {
	if (!auth && (isB2B || !displayMode)) {
		return enableDarkModeForGuest ? THEME_MODE.DARK : THEME_MODE.LIGHT
	}

	if (isB2B) {
		return enableDarkMode
			? enableLightMode
				? displayMode
				: THEME_MODE.DARK
			: THEME_MODE.LIGHT
	}

	return displayMode
}
