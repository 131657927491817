import {
	LIVE_STREAM_ACTION_TYPE,
	LIVE_STREAM_ELEMENT_ID,
} from '../../constants/liveStream'
import { buildRouteUrl } from '../Url'
import { SPORT_ID, SPORT_NAME } from 'constants'

export const initMiniPlayerDOMEvent = ({
	handleShowMiniPlayer,
	handleCloseMiniPlayer,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER,
		handleShowMiniPlayer,
	)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER,
		handleCloseMiniPlayer,
	)
}

export const removeMiniPlayerDOMEvent = ({
	handleShowMiniPlayer,
	handleCloseMiniPlayer,
}) => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER,
		handleShowMiniPlayer,
	)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER,
		handleCloseMiniPlayer,
	)
}

export const mainPlayerTriggerMiniPlayer = ({
	navigate,
	routes,
	mainEventData,
	liveProps,
}) => {
	const { url } = liveProps.status
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.SHOW_MINI_PLAYER, {
		detail: { streamUrl: url, mainEventData },
	})
	root.dispatchEvent(event)
	!window?.env?.optimalMiniPlayerMinimizedEnabled &&
		navigate(
			buildRouteUrl(
				routes.sportLive,
				{
					sportCode: SPORT_ID[SPORT_NAME['e-sports']],
				},
				true,
			),
		)
}

export const triggerCloseMiniPlayer = () => {
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(LIVE_STREAM_ACTION_TYPE.CLOSE_MINI_PLAYER)
	root.dispatchEvent(event)
}

export const initLiveStreamPlayerDOMEvent = ({ handleToggleLiveStreamTab }) => {
	if (!window?.env?.optimalMiniPlayerMinimizedEnabled) return
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.addEventListener(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		handleToggleLiveStreamTab,
	)
}

export const removeLiveStreamPlayerDOMEvent = ({
	handleToggleLiveStreamTab,
}) => {
	if (!window?.env?.optimalMiniPlayerMinimizedEnabled) return
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	root.removeEventListener(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		handleToggleLiveStreamTab,
	)
}

export const triggerToggleLiveStreamTab = (isExpand, isLSTab) => {
	if (!window?.env?.optimalMiniPlayerMinimizedEnabled) return
	const root = document.getElementById(LIVE_STREAM_ELEMENT_ID.ROOT)
	const event = new CustomEvent(
		LIVE_STREAM_ACTION_TYPE.TOGGLE_LIVE_STREAM_TAB,
		{
			detail: { isExpand, isLSTab },
		},
	)
	root.dispatchEvent(event)
}