import { getFirstLang } from 'services/Url'

let instance
let language = getFirstLang()

class Locale {
	constructor() {
		if (instance) {
			throw new Error('You can only create one instance!')
		}
		instance = this
	}

	setLanguage(newLang) {
		language = newLang
	}

	getLanguage() {
		return language
	}
}

const localeInstance = Object.freeze(new Locale())

export default localeInstance
