import { MEDIA } from 'constants/common'

export const media = {
	sm: '768px',
	md: '1024px',
	lg: '1280px',
}

export const borders = {
	small: '2px',
	medium: '4px',
}

export const fontSize = {
	xxs: '10px',
	xs: '11px',
	s: '12px',
	m: '13px',
}

export const fontWeight = {
	normal: '400',
	medium: '500',
	bold: '700',
}

export const space = {
	small: '5px',
	normal: '10px',
	medium: '15px',
	large: '20px',
}

export const images = {
	betslip: {
		single: {
			defaultLight: `url('/standardst/img/icons/betslip-empty.default.svg')`,
			defaultDark: `url('/standardst/img/icons/betslip-empty.dark.svg')`,
			maverick: `url('/standardst/img/icons/betslip-empty.maverick.svg')`,
		},
	},
}

export const textVariant = {
	light: '#fff',
}

export const breakpoints = {
	[MEDIA.DESKTOP]: 1280,
	[MEDIA.TABLET]: 1024,
	[MEDIA.MOBILE]: 768,
	[MEDIA.SMALL_MOBILE]: 360,
}

export const PIXEL_ATTRIBUTES = [
	'height',
	'min-height',
	'max-height',
	'width',
	'max-width',
	'min-width',
	'margin',
	'margin-inline',
	'margin-block',
	'margin-left',
	'margin-right',
	'margin-top',
	'margin-bottom',
	'padding',
	'padding-inline',
	'padding-block',
	'padding-left',
	'padding-right',
	'padding-top',
	'padding-bottom',
	'font-size',
	'gap',
]
