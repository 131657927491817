import storage from 'redux-persist/lib/storage'

export const User = {
	key: 'User',
	keyPrefix: '_',
	storage,
	whitelist: [
		'isLoggedIn',
		'enabledPersonalMessage',
		'enableQuickBet',
		'enableQuickBetNotification',
	],
}

export const Betslip = {
	key: 'Betslip',
	keyPrefix: '_',
	storage,
	whitelist: ['selections', 'bets'],
}
