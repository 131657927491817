import { buildLeftMenuLeaguesURL } from '../Url'
import { SPORT_NAME } from '../../constants'
import { routesEsportsHub } from 'services/Routes/routes'

/**
 *
 * @typedef {Object} GameResponse
 * @property {string} code - game code
 * @property {string} name - game name
 * @property {string} englishName - game name at English
 * @property {number} totalEvents - current total event
 * @property {boolean} onlyFuture - flag check has outright
 */
/**
 *
 * @typedef {Object} Game
 * @property {string} id - game id
 * @property {string} gameCode - game code
 * @property {string} name - game name
 * @property {string} englishName - game name at English
 * @property {string} urlPath - url use by Link of react-router-dom
 * @property {number} totalEvents - current total event
 * @property {boolean} onlyFuture - flag check has outright
 */
/**
 *
 * @param {GameResponse[]} allGamesResponse
 * @returns {{allGamesIds: string[] | [], game: {string: Game} | {}}}
 */
export const createListGames = (allGamesResponse) => {
	const result = {
		allGamesIds: [],
		game: {},
	}
	if (!allGamesResponse?.length) {
		return result
	}

	allGamesResponse.forEach((game) => {
		const temp = {
			id: game?.gameCode,
			gameCode: game?.gameCode,
			name: game?.name,
			totalEvents: game?.totalEvents,
			englishName: game?.englishName,
			urlPath: buildLeftMenuLeaguesURL({
				sportId: SPORT_NAME.esports,
				gameCode: game?.gameCode,
			}),
			onlyFuture: game?.onlyFuture,
		}
		Object.assign(result.game, { [temp.id]: temp })
		result.allGamesIds.push(temp.id)
	})

	result.allGamesIds = result.allGamesIds.sort((firstGameId, secondGameId) =>
		result.game?.[firstGameId]?.name?.localeCompare(
			result.game?.[secondGameId]?.name,
		),
	)
	return result
}

/**
 *
 * @param {GameResponse[]} allGamesResponse
 * @returns {{allGamesIds: string[] | [], games: {string: Games} | {},totalEventsAllGames: number || 0 }}
 */
export const createEsportsHubGames = (allGamesResponse) => {
	const result = {
		allGamesIds: [],
		games: {},
		totalEventsAllGames: 0,
	}
	if (!allGamesResponse?.length) {
		return result
	}

	allGamesResponse.forEach((game) => {
		const temp = {
			id: game?.gameCode,
			gameCode: game?.gameCode,
			name: game?.name,
			totalEvents: game?.totalEvents,
			hasLive: game?.hasLive,
			urlPath: buildLeftMenuLeaguesURL(
				routesEsportsHub.game,
				{
					gameCode: game?.gameCode,
				},
				true,
			),
			onlyFuture: game?.onlyFuture,
		}
		Object.assign(result.games, { [temp.id]: temp })
		result.allGamesIds.push(temp.id)
		result.totalEventsAllGames += game?.totalEvents
	})

	result.allGamesIds = result.allGamesIds.sort((firstGameId, secondGameId) =>
		result.games?.[firstGameId]?.name?.localeCompare(
			result.games?.[secondGameId]?.name,
		),
	)

	return result
}

/**
 *
 * @param gameCode
 * @returns {string: Game Name}
 */
export const getGameNameByGameCode = (gameCode) => {
	if (gameCode.includes('cs-go')) return gameCode.replace('-', ':')
	return gameCode
		.split('-')
		.map((word) => {
			return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
		})
		.join(' ')
}
