import { createSelector } from 'reselect'
import { sportServices } from '../sportServicesRTK'
import { ODDS_SELECTION_TYPE } from 'constants/common'
import { separateOddsId } from 'services/Selections'
import { ERROR_MESSAGES } from 'apps/components/Sportsbook/Betslip/Messages/messages'

const PARLAY_RESTRICTIONS = {
	ALL: 0,
	DISABLED: 1,
	NORMAL: 2,
}

export const selectSportsResult =
	sportServices.endpoints.getSportLeftMenu.select(null)

export const selectSports = createSelector(
	selectSportsResult,
	(sportsResult) => sportsResult?.data || {},
)

/** BET SLIP */
export const selectOddsSelections = createSelector(
	[
		(state) => state.Betslip.selections,
		(_, oddsSelectionsType) => oddsSelectionsType,
		(_, oddsSelectionsType, leagueId) => leagueId,
	],
	(selections, type, leagueId) => {
		switch (type) {
			case ODDS_SELECTION_TYPE.OUTRIGHT:
				return selections.filter(
					(selection) =>
						selection.oddsSelectionsType === ODDS_SELECTION_TYPE.OUTRIGHT &&
						selection.leagueId === leagueId,
				)
			default:
				return selections.filter(
					(selection) =>
						selection.oddsSelectionsType !== ODDS_SELECTION_TYPE.OUTRIGHT,
				)
		}
	},
)

export const getSelectionNumber = createSelector(
	[(state) => state.Betslip.selections],
	(selections) => selections.length,
)

export const getSelectionOddsId = createSelector(
	[(state) => state.Betslip.selections],
	(selections) => selections.map((selection) => selection.oddsId),
)

export const getSelections = createSelector(
	[(state) => state.Betslip.selections],
	(selections) => {
		const { parlaySelections, selectionsOnlySingle } = selections.reduce(
			(result, selection) => {
				if (
					[PARLAY_RESTRICTIONS.ALL, PARLAY_RESTRICTIONS.NORMAL].includes(
						selection.parlayRestriction,
					) &&
					selection.oddsSelectionsType === 'NORMAL'
				) {
					result.parlaySelections.push(selection)
				} else {
					result.selectionsOnlySingle.push(selection)
				}
				return result
			},
			{
				parlaySelections: [],
				selectionsOnlySingle: [],
			},
		)

		const filteredOddsId = parlaySelections.map((selection) => {
			const correlated = parlaySelections.some((s) => {
				if (selection.oddsId !== s.oddsId) {
					const oddsIdSelection = separateOddsId(selection.oddsId)
					const _separation = separateOddsId(s.oddsId)
					return oddsIdSelection.eventId === _separation.eventId
				}
				return false
			})
			if (correlated) {
				return {
					...selection,
					parlayErrorCode: ERROR_MESSAGES.CORRELATED,
				}
			}
			return selection
		})

		return {
			selections: selections,
			selectionsInvalidParlay: selectionsOnlySingle,
			parlaySelections: filteredOddsId,
		}
	},
)

export const getSelectionQuickBet = createSelector(
	[(state) => state.Betslip.quickBet],
	(quickBet) => ({
		selections: quickBet.selection ? [quickBet.selection] : [],
		coordinateX: quickBet.coordinateX,
		coordinateY: quickBet.coordinateY,
	}),
)
