import { SPORT_NAME } from 'constants'
import { buildLeaguesURL, buildRouteUrl } from '../Url'
import { isEmpty } from 'lodash'
import { routesEsportsHub } from 'services/Routes/routes'
import {
	removeGameCodeInLeagueCode,
	removeGameNameInLeagueName,
} from 'services/Events'
import { slugify } from 'services/Common'

/**
 *
 * @typedef {Object} LeagueResponse
 * @property {number} id - sport ID or league ID
 * @property {string} name - sport name or league name
 * @property {string} englishName - name leagues at English
 * @property {string} leagueCode - league code use to build url
 * @property {string} gameCode - game code use to build url for Esport
 * @property {number} totalEvents - current total event
 * @property {number} featuresOrder - order of Top Leagues
 */
/**
 *
 * @typedef {Object} League
 * @property {number} id - sport ID or league ID
 * @property {string} name - sport name or league name
 * @property {string} englishName - name leagues at English
 * @property {string} leagueCode - league code use to build url
 * @property {string} gameCode - game code use to build url for Esport
 * @property {string} urlPath - url use by Link of react-router-dom
 * @property {number} totalEvents - current total event
 * @property {number} featuresOrder - order of Top Leagues
 * @property {boolean=} isFeatured - flag detect Top League
 */
/**
 *
 * @param {LeagueResponse[]} allLeaguesResponse
 * @param {number} sportId
 * @returns {{allLeaguesIds: number[] | [], topLeaguesIds: number[] | [], alphabetLists: [{char: string, leagueIds: number[]}], league: {number: League} | {}}}
 */
export const createListLeagues = (allLeaguesResponse, sportId) => {
	const result = {
		allLeaguesIds: [],
		topLeaguesIds: [],
		alphabetLists: [],
		league: {},
	}

	if (!allLeaguesResponse?.length) {
		return result
	}

	allLeaguesResponse.forEach((league) => {
		const temp = {
			id: league.id,
			name: league.name,
			totalEvents: league.totalEvents,
			isFeatured: league.featuresOrder >= 0,
			featuresOrder: league.featuresOrder,
			englishName: league.englishName,
			leagueCode: league.leagueCode,
			gameCode: league?.gameCode,
			urlPath: buildLeaguesURL(sportId, league?.leagueCode, league?.gameCode),
		}
		Object.assign(result.league, { [league.id]: temp })
		result.allLeaguesIds.push(league.id)

		if (temp.isFeatured) {
			result.topLeaguesIds.push(league.id)
		}
	})

	result.allLeaguesIds = result.allLeaguesIds.sort(
		(firstLeagueId, secondLeagueId) =>
			result.league?.[firstLeagueId]?.name?.localeCompare(
				result.league?.[secondLeagueId]?.name,
			),
	)

	result.topLeaguesIds = result.topLeaguesIds.sort(
		(firstLeagueId, secondLeagueId) =>
			result.league?.[firstLeagueId]?.featuresOrder -
			result.league?.[secondLeagueId]?.featuresOrder,
	)

	if (sportId === SPORT_NAME.soccer) {
		result.alphabetLists = result.allLeaguesIds.reduce(
			(currentList, leagueId) => {
				const char = result.league?.[leagueId]?.name[0]?.toLowerCase()
				const lastIndex = currentList?.length - 1
				const lastCharInList = currentList?.[lastIndex]?.char
				if (lastCharInList === char) {
					currentList[lastIndex] = {
						...currentList[lastIndex],
						leagueIds: [...currentList[lastIndex].leagueIds, leagueId],
					}
					return [...currentList]
				}
				return [...currentList, { char, leagueIds: [leagueId] }]
			},
			[],
		)
		result.allLeaguesIds = []
	}

	return result
}

/**
 *
 * @param {LeagueResponse[]} allLeaguesResponse
 * @param {string} gameCode
 * @returns {{groupLeagueByGame: {number: League} | {}, groupInsession: number[] | []}}
 */
export const createListLeaguesByGames = (allLeaguesResponse, gameCode) => {
	const TOP_GAMES = window.env.esportsTournamentTopGames
	const sortedGroupedByGame = {}
	const result = {
		groupLeagueByGame: {},
		groupInsession: [],
	}

	if (!allLeaguesResponse?.length) {
		return result
	}

	allLeaguesResponse.forEach((league) => {
		if (league.gameCode) {
			if (!result.groupLeagueByGame[league.gameCode]) {
				result.groupLeagueByGame[league.gameCode] = []
			}

			const leaguesEsports = {
				...league,
				urlPath: buildRouteUrl(
					routesEsportsHub.tournament,
					{
						gameCode: league.gameCode,
						leagueCode: slugify(
							removeGameCodeInLeagueCode(league.leagueCode, league.gameCode),
						),
					},
					true,
				),
				isShowIcon: !gameCode && league.featuresOrder !== -1,
				leagueName: removeGameNameInLeagueName(league.name, league.gameName),
			}
			result.groupLeagueByGame[league.gameCode].push(leaguesEsports)

			if (leaguesEsports.featuresOrder !== -1) {
				result.groupInsession.push(leaguesEsports)
			}
		}
	})

	const topGames = Object.keys(result.groupLeagueByGame)
		.filter((game) => TOP_GAMES.includes(game))
		.sort((a, b) => TOP_GAMES.indexOf(a) - TOP_GAMES.indexOf(b))

	const groupOtherGames = Object.keys(result.groupLeagueByGame)
		.filter((game) => !TOP_GAMES.includes(game))
		.sort()

	topGames.concat(groupOtherGames).forEach((game) => {
		sortedGroupedByGame[game] = result.groupLeagueByGame[game]
	})

	result.groupLeagueByGame = sortedGroupedByGame

	if (!isEmpty(result.groupInsession)) {
		const topGames = result.groupInsession
			.filter((game) => TOP_GAMES.includes(game.gameCode))
			.sort((a, b) => TOP_GAMES.indexOf(a) - TOP_GAMES.indexOf(b))

		const otherGames = result.groupInsession
			.filter((game) => !TOP_GAMES.includes(game.gameCode))
			.sort((a, b) => a.name.localeCompare(b.name))

		result.groupInsession = topGames.concat(otherGames)
	}

	return result
}

export const matchSpecialLeague = (text) => {
	const cleanText = text.replaceAll(/[#]/g, '')
	const regex =
		/(futures|outright|group_[a-z]|regular_season_wins|nba_all_star_three_point_contest|special)/
	return regex.test(cleanText)
}
