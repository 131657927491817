export const MARKET_TAB_TYPE = {
	PERIODS: 'periods',
	SPECIALS: 'specials',
	ALL: 'all',
	TEAM_TOTAL: 'team-total',
	BOOKINGS: 'bookings',
	CORNERS: 'corners',
	FUTURES: 'futures',
	KILLS: 'kills',
	ALTERNATE_LINES: 'alternate-lines',
	GAME_MARKETS: 'game-markets',
	SET_MARKETS: 'set-markets',
	DART_180S_MARKETS: 'dart-180s-markets',
	POINTS_MARKETS: 'points-markets',
	HITS_RUNS_ERRORS_MARKETS: 'hits-runs-errors-markets',
}

export const NORMAL_PERIOD = ['period', 'all', 'corners', 'bookings']

export const MARKET_TAB = {
	ALL: {
		keyTranslate: 'market.tab.all',
		defaultMessage: 'All',
		periodKey: '',
		hash: '#all',
		value: MARKET_TAB_TYPE.ALL,
		type: MARKET_TAB_TYPE.ALL,
		show: true,
	},
	TEAM_TOTAL: {
		keyTranslate: 'market.tab.team_total',
		defaultMessage: 'Team Total',
		periodKey: '',
		hash: '#team-total',
		value: MARKET_TAB_TYPE.TEAM_TOTAL,
		type: MARKET_TAB_TYPE.TEAM_TOTAL,
		show: true,
	},
	CORNERS: {
		keyTranslate: 'market.tab.corners',
		defaultMessage: 'Corners',
		periodKey: '',
		hash: '#corners',
		value: MARKET_TAB_TYPE.CORNERS,
		type: MARKET_TAB_TYPE.CORNERS,
		show: true,
	},
	BOOKINGS: {
		keyTranslate: 'market.tab.bookings',
		defaultMessage: 'Bookings',
		periodKey: '',
		hash: '#bookings',
		value: MARKET_TAB_TYPE.BOOKINGS,
		type: MARKET_TAB_TYPE.BOOKINGS,
		show: true,
	},
	FUTURES: {
		keyTranslate: 'odd_page.futures',
		defaultMessage: 'Futures',
		periodKey: '',
		hash: '#futures',
		value: MARKET_TAB_TYPE.FUTURES,
		type: MARKET_TAB_TYPE.FUTURES,
		show: true,
	},
	KILLS: {
		keyTranslate: 'market.tab.kills',
		defaultMessage: 'Kills',
		periodKey: '',
		hash: '#kills',
		value: MARKET_TAB_TYPE.KILLS,
		type: MARKET_TAB_TYPE.KILLS,
		show: true,
	},
	ALTERNATE_LINES: {
		keyTranslate: 'market.tab.alternate_lines',
		defaultMessage: 'Alternate Lines',
		periodKey: '',
		hash: '#alternate-lines',
		value: MARKET_TAB_TYPE.ALTERNATE_LINES,
		type: MARKET_TAB_TYPE.ALTERNATE_LINES,
		show: true,
	},
	GAME_MARKETS: {
		keyTranslate: 'market.tab.game_markets',
		defaultMessage: 'Game Markets',
		periodKey: '',
		hash: '#game',
		value: MARKET_TAB_TYPE.GAME_MARKETS,
		type: MARKET_TAB_TYPE.GAME_MARKETS,
		show: true,
	},
	SET_MARKETS: {
		keyTranslate: 'market.tab.set_markets',
		defaultMessage: 'Set Markets',
		periodKey: '',
		hash: '#set',
		value: MARKET_TAB_TYPE.SET_MARKETS,
		type: MARKET_TAB_TYPE.SET_MARKETS,
		show: true,
	},
	DART_180s_MARKETS: {
		keyTranslate: 'market.tab.dart_180s_markets',
		defaultMessage: 'Dart 180s Markets',
		periodKey: '',
		hash: '#180s',
		value: MARKET_TAB_TYPE.DART_180S_MARKETS,
		type: MARKET_TAB_TYPE.DART_180S_MARKETS,
		show: false,
	},
	POINTS_MARKETS: {
		keyTranslate: '',
		defaultMessage: '',
		periodKey: '',
		hash: '',
		value: MARKET_TAB_TYPE.POINTS_MARKETS,
		type: MARKET_TAB_TYPE.POINTS_MARKETS,
		show: true,
	},
	HITS_RUNS_ERRORS_MARKETS: {
		keyTranslate: 'market.tab.hits_runs_errors',
		defaultMessage: 'Hits + Runs + Errors',
		periodKey: '',
		hash: '#hits-runs-errors',
		value: MARKET_TAB_TYPE.HITS_RUNS_ERRORS_MARKETS,
		type: MARKET_TAB_TYPE.HITS_RUNS_ERRORS_MARKETS,
		show: true,
	},
}

export const RESULTING_UNIT = {
	GAMES: 'Games',
	SETS: 'Sets',
	DART_180S: '180s',
	POINTS: 'Points',
	REGULAR: 'Regular',
	HITS_RUNS_ERRORS: 'Hits + Runs + Errors',
}

export const TEAM_TYPE = {
	HOME: 'HOME',
	AWAY: 'AWAY',
}

export const PERIOD = {
	MATCH: '0',
}
