export const LIVE_STREAM_ACTION_TYPE = {
	LOGIN_TO_WATCH_LIVE_STREAM: 'LOGIN_TO_WATCH_LIVE_STREAM',
	SHOW_MINI_PLAYER: 'SHOW_MINI_PLAYER',
	CHANGE_LANG_LIVE_STREAM: 'CHANGE_LANG_LIVE_STREAM',
	BACK_TO_MAIN_STREAM: 'BACK_TO_MAIN_STREAM',
	CLOSE_MINI_PLAYER: 'CLOSE_MINI_PLAYER',
	CLOSE_MAIN_PLAYER: 'CLOSE_MAIN_PLAYER',
	TOGGLE_LIVE_STREAM_TAB: 'TOGGLE_LIVE_STREAM_TAB',
}

export const LIVE_STREAM_TAB = {
	LIVE_STREAM: 'live-stream',
	LIVE_SCOREBOARD: 'live-scoreboard',
}

export const LIVE_STREAM_ELEMENT_ID = {
	MINI_PLAYER: 'miniPlayerLS',
	MAIN_PLAYER: 'mainPlayerLS',
	LANGUAGE_DROP_DOWN: 'languageDropDownLS',
	ROOT: 'root',
	LIVE_STREAM_TABS: 'liveStreamTabsLS',
}
