import styled from 'styled-components'
import Row, { Col } from 'globalComponents/Row'

const DesktopLayout = styled(Col)`
	background: ${({ theme }) =>
		theme.components.layout?.backgroundImages?.home?.bg};
	background-size: ${({ theme }) =>
		theme.components.layout?.backgroundImages?.size};
	min-height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	@media screen and (min-width: 769px) and (max-width: 920px) {
		overflow-x: auto;
	}
`

const Wrapper = styled(Row)`
	max-width: ${({ theme }) =>
		window.env.isEsportsHub
			? theme.breakpoints.largeDesktop
			: theme.breakpoints.desktop};
	margin: 0 auto;
	align-items: ${({ $stretch }) => ($stretch ? 'stretch' : 'flex-start')};

	&.wrapper-header {
		height: 65px;
	}
`
const LoaderStyled = styled(Row)`
	height: 100vh;
	justify-content: center;
	align-items: center;
`

const MobileLayout = styled(DesktopLayout)`
	position: relative;
`

export { DesktopLayout, Wrapper, LoaderStyled, MobileLayout }
