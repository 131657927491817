// 29 : {
//   0: ['a', 'b'],
//   1: {}
// }

class Periods {
	constructor(data = []) {
		this.dataStore = new Map()
		this.periods = {}
		data.forEach((item) => {
			this.addDescriptionPeriod(item[0], item[1])
		})
	}

	addDescriptionPeriod(sportId, periods = []) {
		if (this.dataStore.has(sportId)) {
			return
		}

		const _periods = periods.reduce((result, period) => {
			const [periodNumber, name, shortName] = period
			result[periodNumber] = [name, shortName]
			this.periods[`${sportId}.${periodNumber}`] = name
			this.periods[`${sportId}.${periodNumber}.short`] = shortName
			return result
		}, {})

		this.dataStore.set(sportId, _periods)
	}
}

export default Periods
