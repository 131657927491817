import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from './rtkApi'
import { languages } from 'constants/i18n'

export const LOCAL_PATH = 'localServices'

export const localServices = createApi({
	reducerPath: LOCAL_PATH,
	baseQuery: axiosBaseQuery(''),
	endpoints: () => ({}),
})

const localApiQuery = localServices.injectEndpoints({
	endpoints: (builder) => ({
		getTemplateFromLocal: builder.query({
			query: (skin) => ({
				endpoint: `${process.env.PUBLIC_URL}/standardst/template/${skin}.json`,
				method: 'get',
			}),
		}),
		getMessagesByLocale: builder.query({
			query: (lang) => ({
				endpoint: `${process.env.PUBLIC_URL}/standardst/translations/${lang}.json`,
				method: 'get',
			}),
			onQueryStarted: async (_args) => {
				const locale = languages.find(
					(lang) => lang.urlPrefix.default === _args,
				)
				if (locale) {
					await locale.injectDateLocale()
				}
			},
		}),
	}),
})

export const { useGetMessagesByLocaleQuery, usePrefetch } = localApiQuery
