import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {}

export const Leagues = createSlice({
	name: 'Leagues',
	initialState,
	reducers: {
		setLeagueData: (state, { payload }) => {
			if (payload.leagueCode) {
				if (!state[`${payload.leagueCode}`]) {
					state[`${payload.leagueCode}`] = {}
				}

				Object.entries(payload).forEach((payloadProp) => {
					const [key, value] = payloadProp
					if (typeof value == 'string') {
						state[`${payload.leagueCode}`][key] = value
					} else {
						if (!state[`${payload.leagueCode}`][key]) {
							state[`${payload.leagueCode}`][key] = {}
						}
						Object.entries(value).forEach((vl) => {
							const [keyVL, valueVL] = vl
							if (keyVL !== 'leagueRefreshAll') {
								state[`${payload.leagueCode}`][key][keyVL] = valueVL
							} else {
								if (value.refreshAll) {
									state[`${payload.leagueCode}`][key][keyVL] = valueVL
								}
							}
						})
					}
				})
			}
		},
		setLeagueDataCorner: (state, { payload }) => {
			if (payload.leagueCode) {
				if (!state[`${payload.leagueCode}corner`]) {
					state[`${payload.leagueCode}corner`] = {}
				}
				Object.entries(payload).forEach((payloadProp) => {
					const [key, value] = payloadProp
					if (typeof value == 'string') {
						state[`${payload.leagueCode}corner`][key] = value
					} else {
						if (!state[`${payload.leagueCode}corner`][key]) {
							state[`${payload.leagueCode}corner`][key] = {}
						}
						Object.entries(value).forEach((vl) => {
							const [keyVL, valueVL] = vl
							if (keyVL !== 'leagueRefreshAll') {
								state[`${payload.leagueCode}corner`][key][keyVL] = valueVL
							} else {
								if (value.refreshAll) {
									state[`${payload.leagueCode}corner`][key][keyVL] = valueVL
								}
							}
						})
					}
				})
			}
		},
		setLeagueDataBooking: (state, { payload }) => {
			if (payload.leagueCode) {
				if (!state[`${payload.leagueCode}booking`]) {
					state[`${payload.leagueCode}booking`] = {}
				}
				Object.entries(payload).forEach((payloadProp) => {
					const [key, value] = payloadProp
					if (typeof value == 'string') {
						state[`${payload.leagueCode}booking`][key] = value
					} else {
						if (!state[`${payload.leagueCode}booking`][key]) {
							state[`${payload.leagueCode}booking`][key] = {}
						}
						Object.entries(value).forEach((vl) => {
							const [keyVL, valueVL] = vl
							if (keyVL !== 'leagueRefreshAll') {
								state[`${payload.leagueCode}booking`][key][keyVL] = valueVL
							} else {
								if (value.refreshAll) {
									state[`${payload.leagueCode}booking`][key][keyVL] = valueVL
								}
							}
						})
					}
				})
			}
		},
		setRefreshAllOfLeagueDate: (state, { payload }) => {
			if (payload.leagueCode && payload.date) {
				const currentState = current(state)
				if (
					currentState[`${payload.leagueCode}${payload.subfix}`] &&
					currentState[`${payload.leagueCode}${payload.subfix}`][payload.date]
				) {
					state[`${payload.leagueCode}${payload.subfix}`][
						payload.date
					].refreshAll = payload.refreshAll
				}
			}
		},
		setRefreshAllDataLeagueDate: (state, { payload }) => {
			if (payload.leagueCode && payload.date) {
				state[`${payload.leagueCode}${payload.subfix}`][
					payload.date
				].leagueRefreshAll = payload.leagues
			}
		},
	},
})

export const {
	setLeagueData,
	setLeagueDataCorner,
	setLeagueDataBooking,
	setRefreshAllOfLeagueDate,
	setRefreshAllDataLeagueDate,
} = Leagues.actions

export default Leagues.reducer
