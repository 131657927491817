import { createApi } from '@reduxjs/toolkit/query/react'
import { OTHER_SERVICES } from 'constants/servicesApi'
import axiosBaseQuery from './rtkApi'

export const OTHER_PATH = 'otherAPI'

export const otherApi = createApi({
	reducerPath: OTHER_PATH,
	baseQuery: axiosBaseQuery(''),
	endpoints: () => ({}),
})

const otherApiQuery = otherApi.injectEndpoints({
	endpoints: (builder) => ({
		getCaptcha: builder.query({
			query: (category) => ({
				endpoint: OTHER_SERVICES.GET_CAPTCHA,
				method: 'get',
				params: {
					category: category,
				},
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
					'X-Requested-With': 'XMLHttpRequest',
				},
			}),
		}),
		pingToDP: builder.query({
			query: (path) => ({
				endpoint: path,
				method: 'head',
			}),
		}),
	}),
})

export const { useGetCaptchaQuery } = otherApiQuery
