import { ODDS_FORMAT } from 'constants/common'
import { Decimal } from 'decimal.js'

export default class PreciseOdds {
	constructor(value, oddsFormat) {
		this.value = new Decimal(value)
		this.format = oddsFormat

		if (!this.valid()) {
			throw new Error(`Invalid odds type "${oddsFormat}" with value "${value}"`)
		}
	}

	rounded() {
		if (this.format === ODDS_FORMAT.AMERICAN) {
			return this.value.round().toNumber()
		}

		const precision_map = {
			[ODDS_FORMAT.INDONESIAN]: 2,
			default: 3,
		}

		const precision = precision_map[this.format] || precision_map.default

		const multiplier = Math.pow(10, precision)

		return Number(
			this.value.mul(multiplier).floor().div(multiplier).toFixed(precision),
		)
	}

	valid() {
		// eslint-disable-next-line no-unused-vars
		const validation_map = {
			[ODDS_FORMAT.AMERICAN]: this.value <= -100 || this.value >= 100,
			[ODDS_FORMAT.DECIMAL]: this.value > 1,
			[ODDS_FORMAT.HONG_KONG]: this.value > 0,
			[ODDS_FORMAT.INDONESIAN]: this.value <= -1 || this.value >= 1,
			[ODDS_FORMAT.MALAY]: this.value >= -1 && this.value <= 1,
		}

		return true
	}

	multiply(odds) {
		const d1 = this.convertTo(ODDS_FORMAT.DECIMAL)
		const d2 = odds.convertTo(ODDS_FORMAT.DECIMAL)

		const newOdds = d1.value * d2.value

		return new PreciseOdds(newOdds, ODDS_FORMAT.DECIMAL).convertTo(this.format)
	}

	americanToDecimal() {
		if (this.value.gt(0)) {
			return this.value.div(100).add(1)
		} else {
			return new Decimal(-100).div(this.value).add(1)
		}
	}

	americanToHk() {
		if (this.value.gt(0)) {
			return this.value.div(100)
		} else {
			return new Decimal(-100).div(this.value)
		}
	}

	americanToIndonesian() {
		return this.value.div(100)
	}

	americanToMalay() {
		return new Decimal(-100).div(this.value)
	}

	americanToProbability() {
		if (this.value.gt(0)) {
			return new Decimal(100).div(this.value.add(100))
		} else {
			return this.value.div(this.value.sub(100))
		}
	}

	decimalToAmerican() {
		if (this.value.gt(1.99)) {
			return new Decimal(100).mul(this.value.sub(1))
		} else {
			return new Decimal(-100).div(this.value.sub(1))
		}
	}

	decimalToHk() {
		return this.value.sub(1)
	}

	decimalToIndonesian() {
		if (this.value.gte(2)) {
			return this.value.sub(1)
		} else {
			return new Decimal(-1).div(this.value.sub(1))
		}
	}

	decimalToMalay() {
		if (this.value.lte(2)) {
			return this.value.sub(1)
		} else {
			return new Decimal(-1).div(this.value.sub(1))
		}
	}

	decimalToProbability() {
		return new Decimal(1).div(this.value)
	}

	hkToAmerican() {
		if (this.value.gt(1)) {
			return new Decimal(100).mul(this.value)
		} else {
			return new Decimal(-100).div(this.value)
		}
	}

	hkToDecimal() {
		return this.value.add(1)
	}

	hktoIndonesian() {
		if (this.value.gte(1)) {
			return this.value
		} else {
			return new Decimal(-1).div(this.value)
		}
	}

	hkToMalay() {
		if (this.value.lte(1)) {
			return this.value
		} else {
			return new Decimal(-1).div(this.value)
		}
	}

	hkToProbability() {
		return new Decimal(1).div(this.value.add(1))
	}

	indonesianToAmerican() {
		return this.value.mul(100)
	}

	indonesianToDecimal() {
		if (this.value.gt(1)) {
			return this.value.add(1)
		} else {
			return new Decimal(1).div(this.value).mul(-1).add(1)
		}
	}

	indonesianToHk() {
		if (this.value.gt(0)) {
			return this.value
		} else {
			return new Decimal(-1).div(this.value)
		}
	}

	indonesianToMalay() {
		return new Decimal(1).div(this.value)
	}

	indonesianToProbability() {
		if (this.value.gt(0)) {
			return new Decimal(1).div(this.value.add(1))
		} else {
			return this.value.div(this.value.sub(1))
		}
	}

	malayToAmerican() {
		return new Decimal(-100).div(this.value)
	}

	malayToDecimal() {
		if (this.value.gt(0)) {
			return this.value.add(1)
		} else {
			return new Decimal(-1).div(this.value).add(1)
		}
	}

	malayToHk() {
		if (this.value.gt(0)) {
			return this.value
		} else {
			return new Decimal(-1).div(this.value)
		}
	}

	malaytoIndonesian() {
		return new Decimal(-1).div(this.value)
	}

	malayToProbability() {
		if (this.value.gt(0)) {
			return new Decimal(1).div(this.value.add(1))
		} else {
			return this.value.div(this.value.sub(1))
		}
	}

	probabilityToAmerican() {
		if (this.value.lte(0.5)) {
			return new Decimal(100).mul(new Decimal(1).div(this.value.sub(1)))
		} else {
			return new Decimal(-100).div(new Decimal(1).div(this.value.sub(1)))
		}
	}

	probabilityToDecimal() {
		return new Decimal(1).div(this.value)
	}

	probabilityToHk() {
		return new Decimal(1).div(this.value).sub(1)
	}

	probabilityToIndonesian() {
		const value = new Decimal(1).div(this.value).sub(1)

		if (value.gte(1)) {
			return value
		} else {
			return new Decimal(-1).div(value)
		}
	}

	probabilityToMalay() {
		const value = new Decimal(1).div(this.value).sub(1)

		if (value.lte(1)) {
			return value
		} else {
			return new Decimal(-1).div(value)
		}
	}

	identity() {
		return this.value
	}

	convertTo(oddsFormat) {
		const conversion_map = {
			[ODDS_FORMAT.AMERICAN]: {
				[ODDS_FORMAT.AMERICAN]: this.identity,
				[ODDS_FORMAT.DECIMAL]: this.americanToDecimal,
				[ODDS_FORMAT.HONG_KONG]: this.americanToHk,
				[ODDS_FORMAT.INDONESIAN]: this.americanToIndonesian,
				[ODDS_FORMAT.MALAY]: this.americanToMalay,
			},
			[ODDS_FORMAT.DECIMAL]: {
				[ODDS_FORMAT.AMERICAN]: this.decimalToAmerican,
				[ODDS_FORMAT.DECIMAL]: this.identity,
				[ODDS_FORMAT.HONG_KONG]: this.decimalToHk,
				[ODDS_FORMAT.INDONESIAN]: this.decimalToIndonesian,
				[ODDS_FORMAT.MALAY]: this.decimalToMalay,
			},
			[ODDS_FORMAT.HONG_KONG]: {
				[ODDS_FORMAT.AMERICAN]: this.hkToAmerican,
				[ODDS_FORMAT.DECIMAL]: this.hkToDecimal,
				[ODDS_FORMAT.HONG_KONG]: this.identity,
				[ODDS_FORMAT.INDONESIAN]: this.hktoIndonesian,
				[ODDS_FORMAT.MALAY]: this.hkToMalay,
			},
			[ODDS_FORMAT.INDONESIAN]: {
				[ODDS_FORMAT.AMERICAN]: this.indonesianToAmerican,
				[ODDS_FORMAT.DECIMAL]: this.indonesianToDecimal,
				[ODDS_FORMAT.HONG_KONG]: this.indonesianToHk,
				[ODDS_FORMAT.INDONESIAN]: this.identity,
				[ODDS_FORMAT.MALAY]: this.indonesianToMalay,
			},
			[ODDS_FORMAT.MALAY]: {
				[ODDS_FORMAT.AMERICAN]: this.malayToAmerican,
				[ODDS_FORMAT.DECIMAL]: this.malayToDecimal,
				[ODDS_FORMAT.HONG_KONG]: this.malayToHk,
				[ODDS_FORMAT.INDONESIAN]: this.malaytoIndonesian,
				[ODDS_FORMAT.MALAY]: this.identity,
			},
		}

		return new PreciseOdds(
			conversion_map[this.format][oddsFormat].call(this),
			oddsFormat,
		)
	}
}
