import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const dayUtils = dayjs

export const guessTimeZone = dayjs.tz.guess()

export default dayUtils
