import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	selectedOdds: [],
	maxOdds: {
		isMaxOdd: false,
	},
}

export const OddPage = createSlice({
	name: 'OddPage',
	initialState,
	reducers: {
		setSelectedOdds: (state, { payload }) => {
			state.selectedOdds = payload
		},
		setIsMaxOdd: (state, { payload }) => {
			state.maxOdds = payload
		},
		setRemoveExtendOdd: (state, { payload }) => {
			state.removeExtendOdd = payload
		},
	},
})

export const { setSelectedOdds, setIsMaxOdd, setRemoveExtendOdd } =
	OddPage.actions

export default OddPage.reducer
