import { memberServices } from '../memberServicesRTK'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { CONTAINER } from 'constants/container'
import { B2B_CONFIG_KEY, VIEW_LAYOUT, VIEW_PORT } from 'constants/common'
import { getConfigValue } from 'services/Common'
import { routesStandard } from 'services/Routes/routes'
import { matchPath } from 'react-router-dom'

const EMPTY_VALUE = {}

export const selectTemplateResult = memberServices.endpoints.getTemplate.select(
	process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_THEME : '',
)

export const selectTemplate = createSelector(
	selectTemplateResult,
	(templateResult) => templateResult?.data ?? EMPTY_VALUE,
)

export const selectTemplateByContainer = createSelector(
	selectTemplate,
	(_, container) => container,
	(templateSelector, container) =>
		get(templateSelector, ['template', ...container], {}),
)

export const selectHeaderTemplate = createSelector(
	[
		(state) =>
			selectTemplateByContainer(
				state,
				state.Layout.view === VIEW_PORT.MOBILE
					? [CONTAINER.MOBILE, CONTAINER.HEADER]
					: [CONTAINER.HEADER],
			),
		(state) => {
			const template = selectTemplate(state)
			return template.initDataForComps?.headerContainer?.Maintenance
		},
		(state) => state.Layout.serviceOutage,
		(_, layout) => layout,
	],
	(header, maintenanceTemplate, serviceOutage, layout) => {
		if (layout === VIEW_LAYOUT.ACCOUNT) {
			return matchPath(routesStandard.accountLogin, window.location.pathname)
				? maintenanceTemplate
				: { row: header.row.filter((item) => item.styled?.$topHeader) }
		}
		if (!getConfigValue(B2B_CONFIG_KEY.ENABLED_HEADER_NAVIGATION_BAR, true)) {
			return { row: header.row.filter((item) => !item.styled?.$topHeader) }
		}
		if (!serviceOutage || !maintenanceTemplate) return header

		return maintenanceTemplate
	},
)

export const selectBodyContainer = createSelector(
	selectTemplate,
	(_, mainContainer) => mainContainer,
	(templateSelector, container) =>
		get(templateSelector, ['template', CONTAINER.BODY, 'col', 1, container]),
)

export const selectLangSupport = createSelector(
	selectTemplate,
	(data) => data.globalAttribute?.langSupports || ['en_US'],
)

export const selectValueFromDataNocache = createSelector(
	selectTemplate,
	(_, key) => key,
	(templateSelector, key) => get(templateSelector, ['dataNoCache', key]),
)

export const selectInitData = createSelector(
	selectTemplate,
	(result) => result?.initDataForComps ?? EMPTY_VALUE,
)

export const selectInitDataByContainer = createSelector(
	selectInitData,
	(_, container) => container,
	(templateSelector, container) => get(templateSelector, [container]),
)

export const selectInitDataByComponent = createSelector(
	selectInitData,
	(_, container) => container,
	(_, container, component) => component,
	(templateSelector, container, component) =>
		get(templateSelector, [container, component], null),
)
