import Cookies from 'universal-cookie'

const cookies = new Cookies(document.cookie)

export const getCookie = (key) => cookies.get(key) || ''

export const getAllCookies = (options = {}) => cookies.getAll(options)

export const setCookie = (key, value, options = {}) => {
	const { domain, tld } = getDomain()
	cookies.set(key, value, {
		path: `/`,
		domain: `.${domain}.${tld}`,
		...options,
	})
}

export const removeCookie = (key, options) => {
	const { subDomain, domain, tld } = getDomain()
	cookies.remove(key, {
		path: `/`,
		domain: `.${domain}.${tld}`,
		...options,
	})
	if (process.env.NODE_ENV !== 'production') {
		cookies.remove(key, {
			path: `/`,
			domain: `.${subDomain}.${domain}.${tld}`,
			...options,
		})
	}
}

export const getDomain = () => {
	const host = window.location.host
	const [subDomain, domain, ...tld] = host.split('.')

	return {
		subDomain: subDomain,
		domain: domain,
		tld: tld.join('.'),
	}
}
