import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
	eventDetailCachedKeys: [],
}

export const Caches = createSlice({
	name: 'Caches',
	initialState,
	reducers: {
		setCacheWithKey: (state, { payload }) => {
			state[`${payload.cacheKey || 'noCache'}`] = payload.data
		},
		setCacheMatchupWithKeyAndTime: (state, { payload }) => {
			const currentState = current(state)
			if (!currentState[`${payload.cacheKey}`]) return
			const { leaguesByDate } = currentState[`${payload.cacheKey}`]

			if (payload.leagues && leaguesByDate) {
				const findIndexLeagues = leaguesByDate.findIndex(
					(lg) => lg.time === payload.time,
				)
				if (findIndexLeagues !== -1) {
					state[`${payload.cacheKey}`].leaguesByDate[findIndexLeagues] = payload
				}
			}
		},
		setEventDetailCacheWithKey: (state, { payload }) => {
			state[payload.cacheKey] = payload.data
			if (state.eventDetailCachedKeys.length >= 5) {
				delete state[state.eventDetailCachedKeys?.[0]]
				state.eventDetailCachedKeys = state.eventDetailCachedKeys.filter(
					(key, index) => index >= 1,
				)
			}
			if (
				!state.eventDetailCachedKeys.find((key) => key === payload.cacheKey)
			) {
				state.eventDetailCachedKeys = [
					...state.eventDetailCachedKeys,
					payload.cacheKey,
				]
			}
		},
	},
})

export const {
	setCacheWithKey,
	setEventDetailCacheWithKey,
	setCacheMatchupWithKeyAndTime,
} = Caches.actions

export default Caches.reducer
