/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from 'stores/main'
import { useGetTemplateQuery } from 'stores/RTK/memberServicesRTK'
import { GlobalProvider } from 'apps/context/Global'
import { LoaderStyled } from 'globalComponents/Container'
import Spinner from 'globalComponents/Spinner'
import { useGetMessagesByLocaleQuery } from './stores/RTK/localServicesRTK'
import { checkMatchAnotherView } from './services/Routes/support'
import { KYC_VERIFIED, THEME_MODE } from './constants'
import { register } from 'services/Routes/routes'
import localeInstance from 'apps/types/Locale'
import { getLangByCulture } from 'services/i18n'

const App =
	process.env.REACT_APP_ENV === 'tool'
		? lazy(() => import('./tools/App'))
		: lazy(() => import('./App'))

const Root = () => {
	const SKIN_DEFAULT =
		process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_THEME : ''
	const isMatchAnotherView = checkMatchAnotherView()
	const { data, isLoading, isError, isFetching } = useGetTemplateQuery(
		SKIN_DEFAULT,
		{ skip: isMatchAnotherView },
	)
	const [theme, setTheme] = useState(null)
	const [periods, setPeriods] = useState(null)
	const { data: enJson } = useGetMessagesByLocaleQuery('en', {
		skip: isMatchAnotherView,
	})
	useEffect(() => {
		if (data) {
			setPeriods(data.periods)
			setTheme(data.mode)
		}
	}, [data])

	const toggleTheme = useCallback(
		() =>
			setTheme((prevTheme) => {
				return prevTheme === THEME_MODE.LIGHT
					? THEME_MODE.DARK
					: THEME_MODE.LIGHT
			}),
		[],
	)

	if (isFetching || isLoading || isError || !theme) {
		return (
			<LoaderStyled>
				<Spinner strokeColor='#ff5500' strokeWidth={1} />
			</LoaderStyled>
		)
	}

	if (data) {
		const _global = data.globalAttribute
		const noCache = data.dataNoCache
		if (
			!_global.isB2B &&
			_global.enableVIPSignupForm &&
			noCache.auth &&
			![KYC_VERIFIED.EMAIL_VERIFIED, KYC_VERIFIED.LEGACY_USER].includes(
				noCache.kycStatus,
			)
		) {
			window.location.href = `${
				window.location.origin
			}/${register.emailVerification.replace(
				':lang',
				getLangByCulture(localeInstance.getLanguage()),
			)}`

			return null
		}
	}

	return (
		<GlobalProvider
			value={{
				configs: data?.globalAttribute,
				periods,
				theme,
				toggleTheme,
				setPeriods,
				engMessage: enJson?.en,
				setTheme,
			}}
		>
			<App />
		</GlobalProvider>
	)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Root />
		</PersistGate>
	</Provider>,
)

// reportWebVitals(console.table)
