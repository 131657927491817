import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const Matchup = createSlice({
	name: 'Matchup',
	initialState,
	reducers: {
		setSportData: (state, { payload }) => {
			state[
				`${payload.sportCode}${
					payload.subfixStore ? payload.subfixStore : ''
				}` || 'Soccer'
			] = payload
		},
	},
})

export const { setSportData } = Matchup.actions

export default Matchup.reducer
