import { createSlice } from '@reduxjs/toolkit'
import { sportApiQuery } from '../RTK/sportServicesRTK'
import { cloneDeep, omit } from 'lodash'

const initialState = {
	favouriteItem: {},
	favouriteItemIds: [],
	favouritePayload: [],
	lastState: {},
}

export const FavouriteSports = createSlice({
	name: 'FavouriteSports',
	initialState,
	reducers: {
		addFavourite: (state, { payload }) => {
			state.lastState = omit(cloneDeep(state), 'lastState')
			payload.featuresOrder = state.favouriteItemIds?.length || 0
			state.favouriteItemIds.push(payload.id)
			state.favouriteItem[payload.id] = payload
			state.favouritePayload.push({
				sportId: payload.sportId,
				entityId: payload.id,
				entityType: payload.type,
				order: state.favouritePayload?.length + 1,
			})
		},
		removeFavourite: (state, { payload }) => {
			state.lastState = omit(cloneDeep(state), 'lastState')
			state.favouriteItemIds = state.favouriteItemIds.filter(
				(id) => id?.toString() !== payload.id?.toString(),
			)
			state.favouritePayload = state.favouritePayload
				// eslint-disable-next-line eqeqeq
				.filter(({ entityId }) => entityId != payload.id)
				.map((item, index) => ({ ...item, order: index }))
			delete state.favouriteItem[payload.id]
		},
		orderFavourite: (state, { payload }) => {
			state.lastState = omit(cloneDeep(state), 'lastState')
			state.favouriteItemIds = payload?.map(({ id, type }, index) => {
				state.favouriteItem[id].featuresOrder = index
				return id
			})
			state.favouritePayload = payload?.map((item, index) => ({
				sportId: item.sportId,
				entityId: item.id,
				entityType: item.type,
				order: index,
			}))
		},
		undoFavourite: (state) => {
			state.favouriteItem = state.lastState.favouriteItem
			state.favouriteItemIds = state.lastState.favouriteItemIds
		},
		updateFavouriteTotalEvents: (state, { payload = [] }) => {
			payload.forEach((item) => {
				if (state.favouriteItem[item.id]) {
					state.favouriteItem[item.id].totalEvents = item?.totalEvents
				}
			})
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			sportApiQuery?.endpoints?.getFavouriteLeftMenu?.matchFulfilled,
			(state, { payload }) => {
				state.favouriteItemIds = payload?.favouriteItemIds || []
				state.favouriteItem = payload?.favouriteItem || {}
				state.favouritePayload = payload?.favouritePayload || []
			},
		)
	},
})

export const {
	addFavourite,
	removeFavourite,
	orderFavourite,
	undoFavourite,
	updateFavouriteTotalEvents,
} = FavouriteSports.actions

export default FavouriteSports.reducer
