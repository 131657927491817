export const preferences = {
	timeZoneOffset: 0,
	oddsFormat: 1,
	oddsMode: 2,
	market: 3,
	order: 4,
	sportsSorting: 5,
	enableDefaultStake: 6,
	defaultStake: 7,
	acceptBetterOdds: 8,
	updateBetSlipOdds: 9,
	marketFilter: 10,
	liveScoreNotify: 11,
	timeZoneId: 12,
	liveContainerOnTopEnabled: 13,
	acceptBetConfirmationModal: 14,
	enableQuickBet: 15,
	enableQuickStake: 16,
	valueQuickStake: 17,
	valueDefaultQuickStake: 18,
}
