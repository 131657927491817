import { generatePropsStyle } from 'services/Styles'
import styled, { css } from 'styled-components'

const PROPS_MAPPING = [
	'$flexDirection',
	'$alignItems',
	'$flexWrap',
	'$flexFlow',
	'$justifyContent',
	'$alignContent',
	'$position',
]

const RowInline = styled.div`
	display: flex;
	${({ $gap }) =>
		$gap &&
		css`
			gap: ${$gap}px;
		`}
	${({ $mh }) =>
		$mh &&
		css`
			height: 100%;
		`}
	${(props) => generatePropsStyle(props, PROPS_MAPPING)}
`

const ColInline = styled(RowInline)`
	flex-direction: column;
`

const Row = styled(RowInline)`
	display: flex;
	width: 100%;
	&#nav-header-b2b {
		@media screen and (min-width: 769px) and (max-width: 920px) {
			width: auto;
			overflow-x: auto;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			::-webkit-scrollbar {
				display: none;
			}
		}
	}
`

const Col = styled(Row)`
	flex-direction: column;
`

const RowAlignCenter = styled(RowInline)`
	align-items: center;
	${({ $block }) =>
		$block &&
		css`
			width: 100%;
		`}
`

const RowAlignTop = styled(RowInline)`
	align-items: baseline;
`

const RowCenter = styled(Row)`
	justify-content: space-between;
`

const ColCenter = styled(Col)`
	justify-content: center;
`

const BlockStyled = styled.div`
	position: relative;
	display: inline-block;
	color: ${({ theme }) => theme.components.header.bottomBar.color};
`

const RowContent = styled(Row)`
	${({ $justify }) => {
		switch ($justify) {
			case 'lcr':
			case 'lr':
				return css`
					justify-content: space-between;
				`
			case 'c':
			case 'cr':
				return css`
					justify-content: center;
				`
			case 'r':
				return css`
					justify-content: flex-end;
				`
			default:
				return css`
					justify-content: flex-start;
				`
		}
	}}
`

export {
	RowInline,
	ColInline,
	Col,
	RowCenter,
	ColCenter,
	RowAlignCenter,
	RowAlignTop,
	BlockStyled,
	RowContent,
}

export default Row
