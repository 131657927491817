/**
 *
 * @type {Object.<string, string>}
 * @description These are all message types using on web socket
 */
export const WS_MESSAGE_TYPE = {
	FULL_LEFT_MENU: 'FULL_LEFT_MENU',
	FULL_ODDS: 'FULL_ODDS',
	LEFT_MENU_DISABLED: 'LEFT_MENU_DISABLED',
	LIVE_SCORE_NOTIFIER: 'LIVE_SCORE_NOTIFIER',
	MORE_BET: 'MORE_BET',
	ODDS_DISABLED: 'ODDS_DISABLED',
	PING: 'PING',
	PONG: 'PONG',
	RESET_CONNECTION: 'RESET_CONNECTION',
	SUBSCRIBE: 'SUBSCRIBE',
	UNSUBSCRIBE: 'UNSUBSCRIBE',
	UN_MORE_BET: 'UN_MORE_BET',
	UPDATE_FIXTURE: 'UPDATE_FIXTURE',
	UPDATE_LEFT_MENU: 'UPDATE_LEFT_MENU',
	WEBSOCKET_DISABLED: 'WEBSOCKET_DISABLED',
	UPDATE_ODDS: 'UPDATE_ODDS',
	DELETE_ODDS: 'DELETE_ODDS',
	RUNNING_STATE_CHANGE: 'RUNNING_STATE_CHANGE',
}

/**
 *
 * @type {Object.<string, string>}
 * @description These are all data receive types using on web socket
 */
export const WS_DESTINATION_TYPE = {
	ALL: 'ALL',
	LEFT_MENU: 'LEFT_MENU',
	LIVE_SCORE: 'LIVE_SCORE',
	ODDS: 'ODDS',
	LIVE_EURO: 'LIVE_EURO_ODDS',
	HLE_EURO: 'HLE_EURO_ODDS',
	MATCHUP_EURO: 'MATCHUPS_EURO_ODDS',
	LEAGUE_EURO: 'LEAGUE_EURO_ODDS',
	LEAGUE_EURO_OUTRIGHT: 'LEAGUE_EURO_OUTRIGHT_ODDS',
	SEARCH_EURO: 'SEARCH_EURO_ODDS',
	PARTICIPANT_EURO_ODDS: 'PARTICIPANT_EURO_ODDS',
	PARTICIPANT_EURO_OUTRIGHT_ODDS: 'PARTICIPANT_EURO_OUTRIGHT_ODDS',
	EVENT_DETAILS_EURO_ODDS: 'EVENT_DETAILS_EURO_ODDS',
}

/**
 *
 * @type {Object.<string, string>}
 * @description These are all view types using on web socket
 */
export const WS_VIEW_TYPE = {
	COMPACT: 'COMPACT',
	EURO: 'EURO',
}
