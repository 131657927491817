import { VIEWS } from '../../constants'

export const routesStandard = {
	base: ':lang/standard',
	home: ':lang/standard/home',
	jumpPage: ':lang/standard/fwp',
	blockPage: ':lang/standard/block-page',
	errorPage: ':lang/standard/error-page',
	register: ':lang/register',
	account: ':lang/standard/account',
	accountLogin: ':lang/standard/account/login',
	unifiedAccount: ':lang/:view/account/:targetPage',
	sport: ':lang/standard/:sportCode',
	sports: ':lang/standard/sports',
	sportLeague: ':lang/standard/:sportCode/:leagueCode',
	sportLeagueEvent: ':lang/standard/:sportCode/:leagueCode/:eventName/:eventId',
	sportHighlights: ':lang/standard/:sportCode/highlights',
	esportHighlights: ':lang/standard/esports/highlights',
	sportLive: ':lang/standard/:sportCode/live',
	sportLeagues: ':lang/standard/:sportCode/leagues',
	sportTeasers: ':lang/standard/:sportCode/teasers',
	sportGames: ':lang/standard/:sportCode/games',
	sportRegulation: ':lang/standard/:sportCode/regulation',
	sportFutures: ':lang/standard/:sportCode/futures',
	esportGames: ':lang/standard/esports/games',
	esportGame: ':lang/standard/esports/games/:gameCode',
	esportGameLeagues: ':lang/standard/esports/games/:gameCode/leagues',
	esportGameLive: ':lang/standard/esports/games/:gameCode/live',
	esportGameLeague: ':lang/standard/esports/games/:gameCode/:leagueCode',
	esportGameLeagueEvent:
		':lang/standard/esports/games/:gameCode/:leagueCode/:eventName/:eventId',
	search: ':lang/standard/search',
	favouritePage: ':lang/standard/favourites',
	favourites: ':lang/standard/favourites/:sportCode#:name',
	sportList: ':lang/standard/sports',
}

export const routesStandardEsportsHub = {
	base: ':lang/standard/esports-hub',
	jumpPage: ':lang/standard/esports-hub/fwp',
	blockPage: ':lang/standard/esports-hub/block-page',
	errorPage: ':lang/standard/esports-hub/error-page',
	home: ':lang/standard/esports-hub/home',
	highlights: ':lang/standard/esports-hub/highlights',
	live: ':lang/standard/esports-hub/live',
	games: ':lang/standard/esports-hub/games',
	leagues: ':lang/standard/esports-hub/leagues',
	futures: ':lang/standard/esports-hub/futures',
	game: ':lang/standard/esports-hub/games/:gameCode',
	gameLive: ':lang/standard/esports-hub/games/:gameCode/live',
	gameLeagues: ':lang/standard/esports-hub/games/:gameCode/leagues',
	gameLeague: ':lang/standard/esports-hub/games/:gameCode/:leagueCode',
	event:
		':lang/standard/esports-hub/games/:gameCode/:leagueCode/:eventName/:eventId',
	search: ':lang/standard/esports-hub/search',
	favouritePage: ':lang/standard/esports-hub/favourites',
	favourites: ':lang/standard/esports-hub/favourites/:sportCode#:name',
}

export const routesAsian = {
	register: ':lang/register',
	forgotUserName: ':lang/forgot-email-username',
	forgotPassword: ':lang/forgotpassword',
	myBetFull: ':lang/account/my-bets-full',
	mobile: {
		forgotPassword: 'm/:lang/asian/forgot-password',
		register: 'm/:lang/asian/register',
		myBets: 'm/:lang/asian/my-bets',
		messages: 'm/:lang/asian/messages',
		deposit: 'm/:lang/asian/account/deposit',
	},
}

export const routesUnifiedAccount = {
	personalDetails: ':lang/:view/account/personal-details',
	preferences: ':lang/:view/account/preferences',
	passwordSecurity: ':lang/:view/account/password-security',
	statements: ':lang/:view/account/statements',
	bettingHistory: ':lang/:view/account/betting-history',
	inbox: ':lang/:view/account/inbox',
	announcements: ':lang/:view/account/announcements',
	deposit: ':lang/:view/account/deposit',
	withdrawal: ':lang/:view/account/withdrawal',
	transactionsHistory: ':lang/:view/account/transactions-history',
	myPaymentAccounts: ':lang/:view/account/my-payment-accounts',
	myBonus: ':lang/:view/account/my-bonus',
	selfExclusionLimit: ':lang/:view/account/self-exclusion-limit',
	accountDeactivation: ':lang/:view/account/account-deactivation',
}

export const routesEsportsHub = {
	multiview: `:lang/esports-hub/live/multiview`,
	base: `:lang/esports-hub`,
	games: `:lang/esports-hub/games`,
	game: `:lang/esports-hub/:gameCode`,
	tournament: `:lang/esports-hub/:gameCode/:leagueCode`,
	matchup: `:lang/esports-hub/:gameCode/:leagueCode/:eventName/:eventId`,
	sitemap: `:lang/esports-hub/sitemap`,
}

export const routesAnotherViews = {
	[VIEWS.COMPACT]: ':lang/compact/sports',
	[VIEWS.ASIA]: ':lang/sports',
}

export const register = {
	base: ':lang/compact/register',
	emailVerification: ':lang/compact/register/verify',
	emailVerifySuccessfully: ':lang/compact/register/verified-successfully',
}

export const layouts = {
	Account: [
		routesStandard.account,
		routesStandard.unifiedAccount,
		routesStandard.accountLogin,
	],
	EsportsHub: [
		routesEsportsHub.base,
		routesEsportsHub.game,
		routesEsportsHub.tournament,
		routesEsportsHub.matchup,
	],
}
