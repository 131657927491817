import { createSlice } from '@reduxjs/toolkit'
import { memberServices } from 'stores/RTK/memberServicesRTK'
import { getLocale } from '../../services/i18n'

const ODDS_DEFAULT = 'EU'

const initialState = {
	theme: 'default',
	odds: ODDS_DEFAULT,
	languageCode: getLocale(),
	isFetchOddsWithDynamicParam: false,
}

export const Preferences = createSlice({
	name: 'Preferences',
	initialState,
	reducers: {
		setTheme: (state, { payload }) => {
			state.theme = payload
		},
		setOddType: (state, { payload }) => {
			state.odds = payload
		},
		setLanguageCode: (state, { payload }) => {
			state.languageCode = payload
		},
		setFetchOddsWithDynamicParam: (state, { payload }) => {
			state.isFetchOddsWithDynamicParam = payload
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			memberServices.endpoints.getTemplate.matchFulfilled,
			(state, { payload }) => {
				const { dataNoCache = {}, globalAttribute = {} } = payload || {}
				state.theme = globalAttribute.themeName
				const oddsOptions = dataNoCache.oddsFormatSetting?.split(',') ?? []
				if (!oddsOptions.includes(state.odds)) {
					state.odds = oddsOptions[0]
				}
			},
		)
	},
})

export const {
	setTheme,
	setOddType,
	setLanguageCode,
	setFetchOddsWithDynamicParam,
} = Preferences.actions

export default Preferences.reducer
