/**
 * Updates an object with new values based on a list of keys.
 *
 * @param {Object} sourceObj - The original object to be updated.
 * @param {Object} newObj - The new object that contains the updated values.
 * @param {Array} listKeys - An array of keys or objects. If it's an object, it should have the following properties:
 *    - key: The key to be updated.
 *    - type: The type of the value (e.g., 'string', 'number', 'boolean', 'object', 'array'). This is used to validate the new value.
 *    - allowFalsy: A boolean that indicates whether falsy values are allowed. If it's false, the original value will be kept when the new value is falsy.
 *    - allowEmpty: A boolean that indicates whether empty values are allowed. If it's false, the original value will be kept when the new value is empty.
 *
 * @returns {Object} - The updated object.
 */
export const updateObjWithKeys = (
	sourceObj = {},
	newObj = {},
	listKeys = [],
) => {
	const result = { ...sourceObj }
	listKeys.forEach(({ type, allowFalsy = true, allowEmpty, key }) => {
		switch (type) {
			case 'string': {
				if (typeof newObj?.[key] === 'string') {
					result[key] = allowFalsy
						? newObj[key]
						: newObj?.[key] || sourceObj?.[key]
				}
				break
			}
			case 'number': {
				if (typeof newObj?.[key] === 'number') {
					result[key] = allowFalsy
						? newObj[key]
						: newObj?.[key] || sourceObj?.[key]
				}
				break
			}
			case 'boolean': {
				if (typeof newObj?.[key] === 'boolean') {
					result[key] = newObj[key]
				}
				break
			}
			case 'array': {
				if (Array.isArray(newObj?.[key])) {
					result[key] = allowEmpty
						? newObj[key]
						: newObj?.[key]?.length
						? newObj[key]
						: sourceObj?.[key]
				}
				break
			}
			case 'object': {
				if (typeof newObj?.[key] === 'object' && !Array.isArray(newObj[key])) {
					result[key] = allowEmpty
						? newObj[key]
						: Object.keys(newObj[key]).length
						? newObj[key]
						: sourceObj[key]
				}
				break
			}
			default: {
				result[key] = allowFalsy
					? newObj?.[key]
					: newObj?.[key] || sourceObj?.[key]
			}
		}
	})
	return result
}

/**
 *
 * @param {{}} inputArray
 * @param {object} mapObject
 * @returns {{}}
 */
export const transformArrayToObject = (inputArray = [], mapObject = {}) => {
	const outputObject = {}
	for (let key in mapObject) {
		if (Object.hasOwn(mapObject, key)) {
			const mappedIndex = mapObject[key]
			if (mappedIndex !== undefined && mappedIndex < inputArray.length) {
				outputObject[key] = inputArray[mappedIndex]
			}
		}
	}
	return outputObject
}

/**
 *
 * @param str
 * @returns {boolean}
 */
export const isJsonString = (str) => {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}
