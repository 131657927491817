import { LOGIN_STATUS, ODDS_FORMAT_ID, VIEWS } from 'constants/common'
import { getAllCookies, getCookie, removeCookie } from 'services/Cookie'
import isEmpty from 'lodash/isEmpty'
import { preferences as preferencesMap } from 'constants/cookies'
import store from 'stores/main'
import {
	resetSession,
	setEnableMaintenance,
	setUser,
	setDefaultStake,
} from 'stores/slices/User'
import { setLanguageCode, setOddType } from 'stores/slices/Preferences'
import localeInstance from 'apps/types/Locale'
import {
	getHeaderXAppData,
	headerToObject,
	removeHeaderTokens,
	removeHeaderXAppData,
} from '../../services/LocalStorage'
import { triggerCloseMiniPlayer } from '../../services/LiveStream'
import { buildRouteUrl } from '../../services/Url'
import { routesAnotherViews } from '../../services/Routes/routes'
const COOKIES_IGNORE = ['_partner']

class Session {
	static transformTrustCodeToStorage(loginId = '') {
		const trustCode = getCookie('trustCode') ?? ''
		if (!loginId || !trustCode) {
			return false
		}
		const _trustCode = JSON.parse(localStorage.getItem('trustCode')) || {}
		_trustCode[loginId] = trustCode
		localStorage.setItem('trustCode', JSON.stringify(_trustCode))
	}

	static catchLoginError(result) {
		if (result.error) {
			return 'SERVICE_ERROR'
		}
		switch (result.data) {
			case LOGIN_STATUS.INVALID_CAPTCHA:
				return 'INVALID_CAPTCHA'
			case LOGIN_STATUS.INVALID_USER:
				return 'INVALID_USER'
			case LOGIN_STATUS.WRONG_COUNTRY:
				return 'WRONG_COUNTRY'
			case LOGIN_STATUS.LOGIN_FAILED:
				return 'LOGIN_FAILED'
			default:
				return ''
		}
	}

	static logout() {
		if (window.env?.enableEsportsLiveStreamModule) {
			triggerCloseMiniPlayer()
		}
		if (window.env?.headerBasedEnabled) {
			removeHeaderTokens()
			removeHeaderXAppData()
		} else {
			const cookies = getAllCookies() ?? {}
			for (const [key] of Object.entries(cookies)) {
				if (COOKIES_IGNORE.includes(key?.toLowerCase())) {
					continue
				}
				removeCookie(key)
			}
		}
	}

	static getPreferences() {
		let preferences = window.env.headerBasedEnabled
			? headerToObject(getHeaderXAppData())?.__prefs
			: getCookie('__prefs')
		if (isEmpty(preferences)) return {}
		preferences = JSON.parse(window.atob(decodeURIComponent(preferences)))
		return Object.keys(preferencesMap).reduce((value, item, index) => {
			value[item] = preferences[index]
			return value
		}, {})
	}

	static syncConfigFromPreference() {
		const preferences = this.getPreferences()
		store.dispatch(setOddType(ODDS_FORMAT_ID[preferences?.oddsFormat]))
		store.dispatch(
			setDefaultStake(
				preferences?.enableDefaultStake ? preferences?.defaultStake || 0 : 0,
			),
		)
	}

	static processDataNocache(dataNoCache) {
		if (dataNoCache.auth) {
			this.updateLanguage(dataNoCache?.locale)
			store.dispatch(setUser(dataNoCache))
			this.syncConfigFromPreference()
		} else {
			store.dispatch(resetSession())
			store.dispatch(setEnableMaintenance(dataNoCache.enableMaintenance))
		}
	}

	static updateLanguage(newLanguage) {
		localeInstance.setLanguage(newLanguage)
		store.dispatch(setLanguageCode(newLanguage))
	}

	static checkAndRedirectToDefaultView(userDefaultView = '') {
		if (userDefaultView === VIEWS.FUTURE || !userDefaultView) {
			return
		}
		window.location.href = `${window.location.origin}${buildRouteUrl(
			routesAnotherViews[userDefaultView],
			{},
			true,
		)}`
	}
}

export default Session
