import { POPUP_ACCOUNT_MSG } from '../../constants/postMessage'
import { isEmpty } from 'lodash'
import { updateHeaderTokens, updateHeaderXAppData } from '../LocalStorage'

export const keepAliveSessionForB2BSettingPage = ({ tokens, xAppData }) => {
	if (window.env.headerBasedEnabled && window.popup && !window.popup.closed) {
		window.popup?.postMessage(
			JSON.stringify({
				type: POPUP_ACCOUNT_MSG.KEEP_ALIVE_SESSION,
				data: {
					...(tokens && { tokens }),
					...(xAppData && { xAppData }),
				},
			}),
			window.location.origin,
		)
	}
}

export const initSessionForB2BSettingPage = ({ tokens, xAppData }) => {
	if (window.env.headerBasedEnabled && window.popup && !window.popup.closed) {
		window.popup?.postMessage(
			JSON.stringify({
				type: POPUP_ACCOUNT_MSG.INIT_SESSION,
				data: {
					tokens,
					xAppData,
				},
			}),
			window.location.origin,
		)
	}
}

export const updateTokenOrXAppDataFormB2BSettingPage = ({
	tokens,
	xAppData,
}) => {
	!isEmpty(tokens) && updateHeaderTokens(tokens)
	!isEmpty(xAppData) && updateHeaderXAppData(xAppData)
}
