export const CONTAINER = {
	FOOTER: 'footerContainer',
	HEADER: 'headerContainer',
	BODY: 'bodyContainer',
	HOME: 'HomeContainer',
	SPORTS: 'SportsContainer',
	BET_SLIP: 'BetSlipContainer',
	ACCOUNT: 'AccountContainer',
	MOBILE: 'mobileContainer',
}

export const REGION_CONTAINER = {
	AMERICAN: 'American',
	CANADA: 'Canada',
	CHINA: 'China',
	EUROPE: 'Europe',
	NORTH_AMERICAN: 'North America',
	POLAND: 'Poland',
	SOUTH_KOREA: 'South Korea',
	USA: 'USA',
	WORLD: 'World',
}

export const ROUTES = {
	SPORT_NAME: 'sportName',
	LEAGUE_NAME: 'leagueName',
	GAME_NAME: 'gameName',
	SEARCH: 'search',
	FAVOURITES: 'favourites',
}
