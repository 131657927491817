import Api from 'apps/types/Api'

const axiosBaseQuery =
	(baseUrl) =>
	async ({ endpoint, params, body = {}, method = 'get', headers = {} }) => {
		const query = Api.parseStringParams(params)
		const path = query ? `${endpoint}?${query}` : endpoint
		return await Api.rest(
			`${baseUrl ? `${baseUrl}/` : ''}${path}`,
			method.toLowerCase() === 'get' ? {} : body,
			{ headers: { ...headers }, method: method },
		)
	}

export default axiosBaseQuery
